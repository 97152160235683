import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsArrowLeft, BsPlus } from "react-icons/bs";
import { Oval } from "react-loader-spinner";

const AddDepartamentos = () => {
  const [formData, setFormData] = useState({
    Nombre: "",
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));

    console.log("handleInputChange -> formData:", {
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    try {
      const response = await axios.post(
        `${window.BACKEND_URL}departamentos`,
        formData,
        {
          headers: {
            Authorization: `${sessionStorage.getItem("token")}`,
            "X-Rol": `${sessionStorage.getItem("role")}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 201) {
        alert("Departamento agregado correctamente");
        navigate("/Departamentos");
      }
    } catch (error) {
      console.error("Error al agregar el departamento:", error);
      alert("Error al agregar el departamento");
    } finally {
      setLoading(false);
    }
  };

  const handleBack = () => {
    navigate("/Departamentos");
  };

  return (
    <div className="row">
      <div className="col-12">
        <div className="card">
          <div className="card-header">
            <h3 className="card-title mb-0">Agregar un Departamento</h3>
          </div>
          <div className="card-body">
            <form onSubmit={handleSubmit}>
              <div className="row">
                <div className="col-md-6">
                  <div className="mb-3">
                    <label htmlFor="Nombre" className="form-label">
                      Nombre del Departamento:
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="Nombre"
                      name="Nombre"
                      value={formData.Nombre}
                      onChange={handleInputChange}
                      required
                    />
                  </div>
                </div>
              </div>
              {error && <div className="text-danger">{error}</div>}

              <div className="d-flex justify-content-between">
                <button
                  type="button"
                  className="btn btn-primary"
                  onClick={handleBack}
                >
                  <BsArrowLeft style={{ fontSize: "1.6em" }} /> Regresar
                </button>
                <button type="submit" className="btn btn-primary">
                  {loading ? (
                    <Oval
                      color="#00BFFF"
                      height={50}
                      width={50}
                      loading={loading}
                    />
                  ) : (
                    <>
                      Agregar Departamento{" "}
                      <BsPlus style={{ fontSize: "2em" }} />
                    </>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddDepartamentos;
